import React from "react"

export const withSearch = Component => ({ name = "Search", page }) => {
  Component.displayName = name
  return (
    <Component
      page={page}
    />
  )
}
