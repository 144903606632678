import React from "react"

export const withSearchInner = Component => ({ name = "SearchInner", page }) => {
  // const { searchTerm } = useSearch()
  const { featuredProductsTitle, featuredProductsLink, collection, products } = page

  // const paths = [{ title: `Search result${searchTerm ? ` for "${searchTerm}"` : `""`}` }]

  Component.displayName = name
  return (
    <Component
      // paths={paths}
      featuredProductsTitle={featuredProductsTitle}
      featuredProductsLink={featuredProductsLink}
      collection={collection}
      products={products}
    />
  )
}
