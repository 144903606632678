import React from "react"
import { graphql } from "gatsby"

import { Search as Page } from "../components/Search/Search"

export const query = graphql`
  query {
    page: sanityPageSearch {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      featuredProductsTitle
      featuredProductsLink: _rawFeaturedProductsLink(resolveReferences: { maxDepth: 2 })
      collection: _rawCollection(resolveReferences: { maxDepth: 3 })
      products: _rawProducts(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
