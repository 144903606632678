import React from "react"

import { SearchResults } from "./Results/SearchResults"
import { NoResults, Wrapper } from "./SearchStyles"
import { BreadCrumbs } from "../BreadCrumbs/BreadCrumbs"
import { withSearchInner } from "./withSearchInner"
import FeaturedProducts from "../Sections/FeaturedProducts/FeaturedProducts"

export const SearchInner = withSearchInner(
  ({ paths, searchResults, featuredProductsTitle, featuredProductsLink, collection, products }): JSX.Element => (
    <>
      <BreadCrumbs paths={paths} />
      <Wrapper>
        <SearchResults />
      </Wrapper>
      {searchResults === 0 ? (
        <NoResults>
          <FeaturedProducts title={featuredProductsTitle} link={featuredProductsLink} collection={collection} products={products} />
        </NoResults>
      ) : null}
    </>
  )
)
