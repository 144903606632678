import React from "react"

import { withSearch } from "./withSearch"
import { ReactifySearchProvider, Sensors } from "@usereactify/search"
import config from "../../../config.default"
import { SearchInner } from "./SearchInner"

export const Search = withSearch(
  ({ page }): JSX.Element => (
    <ReactifySearchProvider
      mode="search"
      shopifyPermanentDomain={config?.stores[config?.services?.shopify?.defaultShopName]?.shopifyShopDomain}
    >
      <Sensors />
      <SearchInner page={page} />
    </ReactifySearchProvider>
  )
)
